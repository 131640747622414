// ** React Imports
import React, { } from "react"
// ** Store & Actions
// ** Third Party Components
import { Button, } from "react-bootstrap"
import Joi from "joi"
// ** Custom Components
import ComponentSpinner from "components/spinner/ComponentSpinner"
import FormInput from "components/form/FormInput"
// ** Hooks, context & utils
import { VerticalFormProvider, useVerticalForm, } from "utility/context/VerticalForm"
import { trigramReg, } from "utility/helpers/regs"
import { useAuth, } from "utility/context/Auth"
import useApi from "hooks/useApi"
// ** Conf & helpers
import { type User, } from "conf/types/User"
// ** Styles
// ** Images


const FormComponent = (): JSX.Element => {
  const {
    watch,
  } = useVerticalForm()

  const watchSave = watch("save")

  return (
    <>
      <FormInput
        label="Trigramme"
        type="text"
        name="trigram"
        placeholder="Saisissez votre trigramme"
        containerClass={"mb-3"}
        className="uppercase"
        maxLength={3}
      />

      <FormInput
        label="Mot de passe"
        type="password"
        name="password"
        placeholder="Saisissez votre mot de passe"
        containerClass={"mb-3"}
      />

      <FormInput
        label={{ trueVal: "Enregistrer", falseVal: "Ne pas enregistrer", }}
        type="switch"
        name="save"
        containerClass={"mb-3"}
        watchedValue={watchSave}
      />

      <div className="mb-3 text-center">
        <Button variant="primary" type="submit">
          Connexion
        </Button>
      </div>
    </>
  )
}

interface FormdataInterface {
  trigram: string
  password: string
  save: boolean
}

const LoginForm = (): JSX.Element => {
  const { setConnectedUser, token, } = useAuth()

  /*
    form validation schema
  */
  const schema = Joi.object({
    trigram: Joi.string()
      .required()
      .max(3)
      .pattern(trigramReg)
      .messages({
        "string.empty": "Trigramme requis",
        "string.pattern.base": "Trigramme non valide",
      }),
    password: Joi.string()
      .required()
      .messages({ "string.empty": "Merci de saisir votre mot de passe", }),
    save: Joi.boolean(),
  })

  const loginCommand = useApi<User>({
    status200: (user, errors, save) => {
      if (errors.length === 0 && user !== null) {
        if (save === true) {
          localStorage.setItem("token", token.current ?? "")
        } else {
          localStorage.removeItem("token")
        }
        setConnectedUser(user)
      }
    },
  })

  const onSubmit = (formData: Record<string, any>): void => {
    void loginCommand.request({
      url: "/user/login",
      method: "post",
      parameters: {
        trigram: formData.trigram.toLocaleUpperCase(),
        password: formData.password,
        save: formData.save,
      },
    }, formData.save)
  }

  const initialValues = { save: localStorage.getItem("token") !== null, }

  return (
    <>
      { loginCommand.loading && <ComponentSpinner classname="size-sm" />}

      <div className="text-center w-75 m-auto">
        <h4 className="text-dark-50 text-center mt-0 fw-bold">S&apos;identifier</h4>
        <p className="text-muted mb-4">
          Entrez votre adresse e-mail et votre mot de passe pour accéder au panneau de gestion.
        </p>
      </div>

      <VerticalFormProvider<FormdataInterface>
        id="loginForm"
        onSubmit={onSubmit}
        initialValues={initialValues}
        schema={schema}
      >
        <FormComponent />
      </VerticalFormProvider>
    </>
  )
}

export default LoginForm
