// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
import "./style.scss"
// ** Images

interface ErrorBoundaryProps {
  error: Error
  resetErrorBoundary?: () => void
}

const ErrorBoundary = (props: ErrorBoundaryProps): JSX.Element => {
  const {
    error,
    resetErrorBoundary,
  } = props

  return (
    <div role="alert" className="alertymes">
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: "pre-wrap", }}>
        {/* <details> */}
        <div className="mt-2">
          { error.cause !== undefined && <p>Cause: {JSON.stringify(error.cause, null, " ")}</p> }
          { error.message !== undefined && <p>Message: {error.message}</p> }
          { error.name !== undefined && <p>Name: {error.name}</p> }
        </div>
        <pre className="mt-2">{error.stack}</pre>
      </details>
      <button className="btn btn-gray btn-compact btn-hover-dark mt-3" onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default ErrorBoundary
