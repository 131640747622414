// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { FormControl, InputGroup, } from "react-bootstrap"
import { type RegisterOptions, } from "react-hook-form"
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import { useVerticalForm, } from "utility/context/VerticalForm"
// ** Conf & helpers
// ** Styles
// ** Images


interface PasswordInputProps {
  name: string
  registerOptions?: RegisterOptions
  placeholder?: string
  className?: string
  autoComplete?: string
}

/* Password Input */
const PasswordInput = ({
  name,
  registerOptions,
  placeholder,
  className,
  autoComplete,
  ...otherProps
}: PasswordInputProps): JSX.Element => {
  const [ showPassword, togglePassword, ] = useToggle()

  const {
    register,
    formState: { errors, },
  } = useVerticalForm()

  return (
    <InputGroup className="mb-0">
      <FormControl
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        id={name}
        as="input"
        className={className}
        isInvalid={errors[name] !== undefined}
        {...register(name, registerOptions)}
        autoComplete={autoComplete ?? "password"}
        {...otherProps}
      />
      <div
        className={classNames("input-group-text", "input-group-password", {
          "show-password": showPassword,
        })}
        data-password={showPassword ? "true" : "false"}
      >
        <span className="password-eye" onClick={togglePassword}></span>
      </div>
    </InputGroup>
  )
}

export default PasswordInput
