// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
import type { RouteObject, } from "react-router-dom"
// ** Custom Components
import LayoutWrapper from "../LayoutWrapper"
// import { VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout, } from "layouts"
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

// lazy load all the views

// auth
const ArrayBasic = lazy(async () => await import("views/tests/array/ArrayBasic"))
const ArrayColumnGroups = lazy(async () => await import("views/tests/array/ArrayColumnGroups"))
const ArrayColumnFiltering = lazy(async () => await import("views/tests/array/ArrayColumnFiltering"))
const ArrayColumnOrdering = lazy(async () => await import("views/tests/array/ArrayColumnOrdering"))

const Agenda = lazy(async () => await import("views/tests/agenda/Agenda"))
const ArrayDrag = lazy(async () => await import("views/tests/array/ArrayDrag"))
const Calendar = lazy(async () => await import("views/tests/calendar/Calendar"))
const DragAndDrop = lazy(async () => await import("views/tests/DragAndDrop"))
const Form = lazy(async () => await import("views/tests/Form"))
const ModalsTest = lazy(async () => await import("views/tests/Modals"))
const Objects = lazy(async () => await import("views/tests/Objects"))
const Test = lazy(async () => await import("views/tests/Test"))
const Validation = lazy(async () => await import("views/tests/Validation"))
const Wizard = lazy(async () => await import("views/tests/Wizard"))

const testsRoutes: RouteObject[] = [
  {
    // public routes
    path: "/tests",
    element: <LayoutWrapper publicRoute={true} />,
    children: [
      { path: "agenda", element: <Agenda />, },
      {
        path: "array",
        children: [
          { path: "basic", element: <ArrayBasic />, },
          { path: "column-groups", element: <ArrayColumnGroups />, },
          { path: "column-filtering", element: <ArrayColumnFiltering />, },
          { path: "column-ordering", element: <ArrayColumnOrdering />, },
          { path: "drag", element: <ArrayDrag />, },
        ],
      },
      { path: "calendar", element: <Calendar />, },
      { path: "drag-and-drop", element: <DragAndDrop />, },
      { path: "form", element: <Form />, },
      { path: "modals", element: <ModalsTest />, },
      { path: "objects", element: <Objects />, },
      { path: "test", element: <Test />, },
      { path: "validation", element: <Validation />, },
      { path: "Wizard", element: <Wizard />, },
    ],
  },
]

export default testsRoutes
