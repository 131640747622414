// ** React Imports
import React, { type InputHTMLAttributes, } from "react"
// ** Store & Actions
// ** Third Party Components
import { FormGroup, FormControl, FormCheck, FormLabel, FormSelect, InputGroup, type FormSelectProps, type FormControlProps, } from "react-bootstrap"
import { Typeahead, } from "react-bootstrap-typeahead"
import { Controller, type RegisterOptions, } from "react-hook-form"
import classNames from "classnames"
import { type ReactDatePickerProps, } from "react-datepicker"
// ** Custom Components
import PasswordInput from "./PasswordInput"
import Datepicker, { DatePickerOptions, } from "./Datepicker"
// ** Hooks, context & utils
import { useVerticalForm, } from "utility/context/VerticalForm"
// ** Conf & helpers
import { getNestedValue, } from "utility/helpers/object"
// ** Styles
// ** Images

interface ContainerProps {
  label?: string
  containerClass?: string
  labelClassName?: string
  error?: string
  errorClassName?: string
  children: React.ReactNode
}

const Container = (pros: ContainerProps): JSX.Element => {
  const {
    label,
    containerClass = "",
    labelClassName = "",
    error,
    errorClassName = "",
    children,
  } = pros

  return (
    <FormGroup className={containerClass}>
      {label !== undefined && <FormLabel className={labelClassName}>{label}</FormLabel>}
      {children}
      <FormControl.Feedback type="invalid" className={errorClassName}>{error}</FormControl.Feedback>
    </FormGroup>
  )
}

type FormInputProps<WithRange extends boolean | undefined = undefined> = InputHTMLAttributes<HTMLInputElement> & {
  readonly type?: "hidden" | "text" | "password" | "email" | "number" | "textarea" | "select" | "switch" | "checkbox" | "radio" | "selectMenu" | "date" | "tel" | "color" | "datePicker"
  readonly id?: string
  readonly name: string
  readonly label?: string | { trueVal: string, falseVal: string }
  readonly registerOptions?: RegisterOptions
  readonly containerClass?: string
  readonly labelClassName?: string
  readonly className?: RegisterOptions
  readonly placeholder?: string
  readonly rows?: number
  readonly autoComplete?: string
  readonly Button?: React.ElementType
  readonly multiple?: boolean
  readonly options?: Array<string | Record<string, any>>
  readonly labelKey?: string | ((option: string | Record<string, any>) => string)
  readonly emptyLabel?: string
  readonly defaultInputValue?: string
  readonly clearButton?: boolean
  readonly watchedValue?: any
  readonly datePickerHideAddon?: boolean
  readonly datePickerOptions?: DatePickerOptions<WithRange>
  readonly children?: React.ReactNode
}

const FormInput = <WithRange extends boolean | undefined = undefined>({
  type,
  id,
  name,
  label,
  registerOptions,
  containerClass,
  labelClassName,
  className,
  placeholder,
  rows,
  autoComplete,
  Button,
  multiple = false,
  options = [],
  labelKey,
  emptyLabel = "Aucune correspondance",
  defaultInputValue,
  clearButton = false,
  watchedValue,
  datePickerHideAddon = false,
  datePickerOptions,
  children,
  ...otherProps
}: FormInputProps<WithRange>): JSX.Element => {
  const {
    register,
    formState,
    setValue,
    trigger,
    control,
  } = useVerticalForm()

  const error = getNestedValue(formState.errors, name)

  switch (type) {
    case "hidden":
      return <input type="hidden" {...register(name, registerOptions)} {...otherProps} />
    case "datePicker":
      return (
        <Container
          label={label as string}
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
          errorClassName = "d-block"
        >
          <Controller
            control={control}
            name={name}
            defaultValue=""
            rules={{ required: true, }}
            render={({ field: { onChange, onBlur, value, ref, }, }) => (
              <Datepicker<WithRange>
                // registerOptions={{
                //   ...registerOptions,
                //   ...{ valueAsDate: true, },
                // }}
                options={{
                  ...datePickerOptions,
                  ...{
                    className: classNames("form-control", datePickerHideAddon ? "date" : "form-control-light", className),
                    id,
                    name,
                    placeholderText: placeholder,
                    selected: value as Date | null | undefined,
                  },
                } as ReactDatePickerProps<never, WithRange>}

                {...otherProps}
              />
            )}
          />
        </Container>
      )
    case "password":
      return (
        <Container
          label={label as string}
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
          errorClassName = "d-block"
        >
          <Controller
            control={control}
            name={name}
            defaultValue=""
            rules={{ required: true, }}
            render={({ field: { onChange, onBlur, value, ref, }, }) => (
              <PasswordInput
                name={name}
                onChange={onChange} // send value to hook form
                onBlur={onBlur} // notify when input is touched/blur
                value={value}
                placeholder={placeholder}
                className={className}
                autoComplete={autoComplete}
                registerOptions={registerOptions}
                {...otherProps as FormControlProps}
              />
            )}
          />
        </Container>
      )
    case "select":
      return (
        <Container
          label={label as string}
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
        >
          <FormSelect
            id={id}
            className={className}
            isInvalid={error !== undefined}
            {...register(name, registerOptions)}
            {...otherProps as FormSelectProps}
          >
            {children}
          </FormSelect>
        </Container>
      )
    case "selectMenu":
      return (
        <Container
          label={label as string}
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
        >
          <Typeahead
            id={id}
            multiple={multiple}
            onChange={(selected) => {
              setValue(name, multiple ? selected : selected[0])
              if (formState.isSubmitted) void trigger(name)
            }}
            options={options}
            labelKey={labelKey}
            // renderMenu={(results, menuProps, state) => {
            //   console.log(results, menuProps, state) // eslint-disable-line no-console
            //   return <></>
            // }}
            placeholder={placeholder}
            emptyLabel={emptyLabel}
            disabled={otherProps.disabled}
            defaultInputValue={defaultInputValue}
            isInvalid={error !== undefined}
            clearButton={clearButton}
          />
        </Container>
      )
    case "switch":
      return (
        <Container
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
          errorClassName = "d-block"
        >
          <FormCheck
            type="switch"
            label={typeof label === "object" ? (watchedValue === true ? label.trueVal : label.falseVal) : label}
            id={id}
            isInvalid={error !== undefined}
            {...register(name, registerOptions)}
            {...otherProps}
          />
        </Container>
      )
    case "checkbox":
    case "radio":
      return (
        <Container
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
          errorClassName = "d-block"
        >
          <FormCheck
            id={id}
            type={type}
            label={label as string}
            className={className}
            isInvalid={error !== undefined}
            {...register(name, registerOptions)}
            {...otherProps}
          />
        </Container>
      )
    default:
      return (
        <Container
          label={label as string}
          containerClass={containerClass}
          labelClassName={labelClassName}
          error={error?.message}
          errorClassName = "d-block"
        >
          <InputGroup className="mb-0">
            <FormControl
              type={type}
              placeholder={placeholder}
              id={id}
              as={type === "textarea" ? "textarea" : "input"}
              className={className}
              isInvalid={error !== undefined}
              {...register(name, registerOptions)}
              {...(rows !== undefined && { rows, })}
              {...otherProps as FormControlProps}
              autoComplete={autoComplete ?? name}
            >
              {children ?? null}
            </FormControl>
            {Button !== undefined && <Button />}
          </InputGroup>
        </Container>
      )
  }
}

export default FormInput
