// ** React Imports
import React, { useState, useEffect, createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
import { DateClickArg, DropArg, } from "@fullcalendar/interaction"
import { type EventClickArg, type EventDropArg, type EventInput, type EventRenderRange, } from "@fullcalendar/core"
import { toast, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import useApi from "hooks/useApi"
// ** Conf & helpers
import { defaults, } from "conf/app"
import { useAuth, } from "utility/context/Auth"
import { type Contact, } from "conf/types/Contact"
import { type ToDo, } from "conf/types/ToDo"
// ** Styles
// ** Images

const calendarDefaults = defaults.calendar

interface StandbyContactList {
  myContacts: Contact[]
  teamContacts: Contact[]
  allContacts: Contact[]
}


interface CalendarContextProps {
  readonly events: EventInput[]
  readonly getEventsRequesting: boolean
  readonly storeEventsRequesting: boolean
  readonly isModalOpen: boolean
  readonly onOpenModal: () => void
  readonly onCloseModal: () => void
  readonly eventIndex: number
  readonly addEvent: () => void
  readonly onDateClick: (arg: DateClickArg) => void
  readonly onEventClick: (arg: EventClickArg | EventRenderRange) => void
  readonly onDrop: (arg: DropArg) => void
  readonly onEventDrop: (arg: EventDropArg) => void
  readonly onUpdateEvent: (data: ToDo, comment: string, replacement?: ToDo) => void
  readonly onRemoveEvent: () => void
  readonly onAddEvent: (data: ToDo) => void
  readonly contacts: StandbyContactList
  readonly getStandbyContactListRequesting: boolean
  readonly skipPageReset: boolean
}

const CalendarContext = createContext<CalendarContextProps>({
  events: [],
  getEventsRequesting: false,
  storeEventsRequesting: false,
  isModalOpen: false,
  onOpenModal: () => {},
  onCloseModal: () => [],
  eventIndex: -1,
  addEvent: () => {},
  onDateClick: (arg: DateClickArg) => {},
  onEventClick: (arg: EventClickArg | EventRenderRange) => {},
  onDrop: (arg: DropArg) => {},
  onEventDrop: (arg: EventDropArg) => {},
  onUpdateEvent: (data: ToDo, comment: string, replacement?: ToDo) => {},
  onRemoveEvent: () => {},
  onAddEvent: (data: ToDo) => {},
  contacts: {
    myContacts: [],
    teamContacts: [],
    allContacts: [],
  },
  getStandbyContactListRequesting: false,
  skipPageReset: false,
})

const useCalendar = (): CalendarContextProps => useContext(CalendarContext)


interface CalendarProviderProps {
  children: JSX.Element
}

const CalendarProvider = ({ children, }: CalendarProviderProps): JSX.Element => {
  /*
  * Events handling
  */
  const { connectedUser, } = useAuth()
  const [ events, setEvents, ] = useState<EventInput[]>([])
  const [ eventIndex, setEventIndex, ] = useState(-1)
  // const [ dateInfo, setDateInfo, ] = useState<DateClickArg>({} as DateClickArg)

  const [ contacts, setContacts, ] = useState<StandbyContactList>({ myContacts: [], teamContacts: [], allContacts: [], })
  const [ skipPageReset, setSkipPageReset, ] = useState(false)

  const getTodosCommand = useApi<ToDo[]>({
    status200: (toDoList, errors, onlyActive) => {
      if (errors.length === 0 && Array.isArray(toDoList)) {
        setEvents(toDoList.map(
          todo => {
            return ({
              id: todo.id,
              title: todo.task.description,
              start: todo.deadline,
              allDay: true,
              backgroundColor: todo.isAccomplished ? calendarDefaults.accomplishedStyle.backgroundColor : todo.task.style.backgroundColor,
              borderColor: todo.isAccomplished ? calendarDefaults.accomplishedStyle.borderColor : todo.task.style.borderColor,
              textColor: todo.isAccomplished ? calendarDefaults.accomplishedStyle.color : todo.task.style.color,
              extendedProps: {
                id: todo.id,
                deadline: todo.deadline,
                executionDate: todo.executionDate,
                notes: todo.notes,
                isAccomplished: todo.isAccomplished,
                isActive: todo.isActive,
                created_at: todo.created_at,
                task: todo.task,
                contact: todo.contact,
              },
            } as unknown as EventInput)
          }
        ))
      }
    },
  })

  const storeTodoCommand = useApi<ToDo & { replacement?: ToDo }>({
    status200: (data, errors, contact) => {
      if (errors.length === 0 && data !== null) {
        setSkipPageReset(true)

        // const todo = data.todo as Record<string, unknown> // as ToDo

        if (contact !== undefined) {
          if ((contact as Contact).id !== connectedUser?.id) {
            setEvents(old => [
              ...old,
              ...[
                {
                  id: data.id as unknown as string,
                  title: data.task.description,
                  start: data.deadline,
                  allDay: true,
                  backgroundColor: data.task.style.backgroundColor,
                  borderColor: data.task.style.borderColor,
                  textColor: data.task.style.color,
                  extendedProps: {
                    id: data.id,
                    deadline: data.deadline,
                    executionDate: data.executionDate,
                    notes: data.notes,
                    isAccomplished: data.isAccomplished,
                    isActive: data.isActive,
                    created_at: data.created_at,
                    task: data.task,
                    contact,
                  },
                },
              ],
            ])
          }
          setContacts(old => (
            {
              myContacts: old.myContacts.filter(myContact => myContact.id !== (contact as Contact).id),
              teamContacts: old.teamContacts.filter(myContact => myContact.id !== (contact as Contact).id),
              allContacts: old.allContacts.filter(myContact => myContact.id !== (contact as Contact).id),
            }
          ))
          toast.success("Tâche ajoutée, contact activé")
        } else {
          if (data.isAccomplished) {
            const replacement = data.replacement as ToDo
            setEvents(old => [
              ...(
                calendarDefaults.onlyPending
                  ? old.filter(event => event.extendedProps?.id !== data.id)
                  : old.map(event => event.extendedProps?.id !== data.id
                    ? event
                    : {
                      ...event,
                      ...{
                        id: data.id as unknown as string,
                        start: data.deadline,
                        backgroundColor: calendarDefaults.accomplishedStyle.backgroundColor,
                        borderColor: calendarDefaults.accomplishedStyle.borderColor,
                        textColor: calendarDefaults.accomplishedStyle.color,
                        extendedProps: {
                          ...event.extendedProps,
                          ...{
                            id: data.id,
                            deadline: data.deadline,
                            executionDate: data.executionDate,
                            notes: data.notes,
                            isAccomplished: data.isAccomplished,
                            isActive: data.isActive,
                            created_at: data.created_at,
                          },
                        },
                      },
                    }
                  )
              ),
              ...(data.replacement === undefined
                ? []
                : [
                  {
                    id: replacement.id as unknown as string,
                    title: replacement.task.description,
                    start: replacement.deadline,
                    allDay: true,
                    backgroundColor: replacement.task.style.backgroundColor,
                    borderColor: replacement.task.style.borderColor,
                    textColor: replacement.task.style.color,
                    extendedProps: {
                      id: replacement.id,
                      deadline: replacement.deadline,
                      executionDate: replacement.executionDate,
                      notes: replacement.notes,
                      isAccomplished: replacement.isAccomplished,
                      isActive: replacement.isActive,
                      created_at: replacement.created_at,
                      task: replacement.task,
                      contact: data.contact,
                    },
                  },
                ]
              ),
            ])
          } else {
            setEvents(old => old.map(event => {
              if (event.extendedProps?.id === data.id) {
                return {
                  ...event,
                  ...{
                    start: data.deadline,
                    extendedProps: {
                      ...event.extendedProps,
                      ...{
                        deadline: data.deadline,
                        executionDate: data.executionDate,
                        notes: data.notes,
                        isAccomplished: data.isAccomplished,
                      },
                    },
                  },
                }
              }
              return event
            }))
          }
          toast.success("Tâche modifiée")
        }
      }
    },
  })

  const getStandbyContactListCommand = useApi<{ myContacts: Contact[], teamContacts: Contact[], allContacts: Contact[] }>({
    status200: (data, errors) => {
      if (errors.length === 0 && data !== null) {
        setContacts({
          myContacts: data.myContacts,
          teamContacts: data.teamContacts,
          allContacts: data.allContacts,
        })
      }
    },
  })


  useEffect(() => {
    if (connectedUser === null) {
      setEvents([])
      setContacts({ myContacts: [], teamContacts: [], allContacts: [], })
    } else {
      void getTodosCommand.request({
        url: "/todos",
        method: "get",
        parameters: {
          onlyPending: calendarDefaults.onlyPending,
        },
      })

      void getStandbyContactListCommand.request({
        url: "/user/contacts/standby",
        method: "get",
      })
    }
  }, [ connectedUser, ])

  useEffect(() => {
    setSkipPageReset(false)
  }, [ contacts, ])


  useEffect(() => {
    setSkipPageReset(false)
  }, [ contacts, ])

  /*
   * Modal handling
   */
  const [ isModalOpen, , show, hide, ] = useToggle()
  const onCloseModal = (): void => {
    hide()
    setEventIndex(-1)
    // setDateInfo({} as DateClickArg)
  }
  const onOpenModal = (): void => { show() }

  // on event click
  const addEvent = (): void => {
    setEventIndex(-1)
    onOpenModal()
  }


  /*
   * Calendar events
   */
  // on date click
  const onDateClick = (arg: DateClickArg): void => {
    // setDateInfo(arg)
    // onOpenModal()
  }

  // on event click
  const onEventClick = (arg: EventClickArg | EventRenderRange): void => {
    const id = (arg as EventClickArg).event?.id ?? (arg as EventRenderRange).def.extendedProps.id
    const index = events.findIndex(event => event.id !== undefined && +event.id === +id)
    if (index === -1) return
    if (events[index].extendedProps?.isAccomplished === true) {
      toast.error("Edition impossible car la tâche est aboutie")
      return
    }
    setEventIndex(index)
    onOpenModal()
  }

  // on drop
  const onDrop = (arg: DropArg): void => {
    // const dropEventData = arg
    // const title = dropEventData.draggedEl.title
    // if (title !== null) {
    //   const newEvent = {
    //     id: String(events.length + 1),
    //     title,
    //     start: dropEventData.dateStr,
    //     className: dropEventData.draggedEl.attributes.getNamedItem("data-class").value,
    //   }
    //   const modifiedEvents = [ ...events, ]
    //   modifiedEvents.push(newEvent)

    //   setEvents(modifiedEvents)
    // }
  }

  // on add event
  const onAddEvent = (data: ToDo): void => {
    if (data.contact.id === undefined || data.task.id === undefined) return
    void storeTodoCommand.request({
      url: `/contact/${data.contact.id}/task/${data.task.id}/todo/store`,
      method: "post",
      parameters: {
        deadline: data.deadline,
        notes: data.notes ?? "",
      },
    }, data.contact)

    onCloseModal()
  }

  //  on update event
  const onUpdateEvent = (data: ToDo, comment: string, replacement?: ToDo): void => {
    const url = `/todo/${data.id}/${replacement !== undefined ? `task/${replacement.task.id}/store` : "store"}`

    const parameters = {
      ...{
        deadline: data.deadline,
        notes: data.notes ?? "",
        isAccomplished: data.isAccomplished,
      },
      ...(comment !== "" ? { comment, } : null),
      ...(replacement !== undefined ? { newDeadline: replacement.deadline, newNotes: replacement.notes, } : null),
    }

    // // eslint-disable-next-line no-console
    // console.log(url)
    // // eslint-disable-next-line no-console
    // console.log(parameters)

    void storeTodoCommand.request({
      url,
      method: "post",
      parameters,
    })

    onCloseModal()
  }

  // on remove event
  const onRemoveEvent = (): void => {
    // const modifiedEvents = [ ...events, ]
    // const idx = modifiedEvents.findIndex((e) => e.id === eventData.id)
    // modifiedEvents.splice(idx, 1)
    // setEvents(modifiedEvents)
    // onCloseModal()
  }

  // on event drop
  const onEventDrop = (arg: EventDropArg): void => {
    // const modifiedEvents = [ ...events, ]
    // const idx = modifiedEvents.findIndex((e) => e.id === String(arg.event.id))
    // modifiedEvents[idx].title = arg.event.title
    // modifiedEvents[idx].className = arg.event.classNames
    // modifiedEvents[idx].start = arg.event.start as DateInput
    // modifiedEvents[idx].end = arg.event.end as DateInput
    // setEvents(modifiedEvents)
  }

  return (
    <CalendarContext.Provider value={{
      events,
      getEventsRequesting: getTodosCommand.loading,
      storeEventsRequesting: storeTodoCommand.loading,
      isModalOpen,
      onOpenModal,
      onCloseModal,
      eventIndex,
      addEvent,
      onDateClick,
      onEventClick,
      onDrop,
      onEventDrop,
      onUpdateEvent,
      onRemoveEvent,
      onAddEvent,
      contacts,
      getStandbyContactListRequesting: getStandbyContactListCommand.loading,
      skipPageReset,
    }}>
      {children}
    </CalendarContext.Provider>
  )
}

export { useCalendar, CalendarProvider, }
