// ** React Imports
import React, { forwardRef, } from "react"
// ** Store & Actions
// ** Third Party Components
import DatePicker, { type ReactDatePickerProps, registerLocale, } from "react-datepicker"
import { InputGroup, FormControl, } from "react-bootstrap"
import fr from "date-fns/locale/fr"
import { format, } from "date-fns"
// import { type RegisterOptions, } from "react-hook-form"
// ** Custom Components
// ** Hooks, context & utils
import { useVerticalForm, } from "utility/context/VerticalForm"
// ** Conf & helpers
import { getNestedValue, } from "utility/helpers/object"
// ** Styles
// ** Images

export type DatePickerOptions<WithRange extends boolean | undefined = undefined> = Omit<ReactDatePickerProps<never, WithRange>, "children" | "className" | "customInput" | "id" | "name" | "onChange" | "placeholderText"> & {
  onChange?: (
    date: WithRange extends false | undefined ? Date | null : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
  ) => void
  hideAddon?: boolean
}


registerLocale("fr", fr)


interface DatepickerInputProps {
  name?: string
  // registerOptions?: RegisterOptions
  placeholder?: string
  className?: string
  selectedValue: Date | null | ""
}

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
  const {
    name,
    // registerOptions,
    placeholder,
    className,
    selectedValue,
    ...otherProps
  } = props

  const {
    // register,
    formState,
  } = useVerticalForm()

  if (name === undefined) return null
  const error = getNestedValue(formState.errors, name)

  return (
    <InputGroup className="mb-0" ref={ref}>
      <FormControl
        type="date"
        id={name}
        placeholder={placeholder ?? ""}
        as="input"
        className={className}
        isInvalid={error !== undefined}
        value={selectedValue !== null && selectedValue !== "" ? format(selectedValue, "yyyy-MM-dd") : undefined}
        // {...register(name, registerOptions)}
        {...otherProps}
      />
    </InputGroup>
  )
})




interface DatepickerProps<WithRange extends boolean | undefined> {
  // registerOptions?: RegisterOptions
  options: ReactDatePickerProps<never, WithRange>
}

const Datepicker = <WithRange extends boolean | undefined = undefined>(props: DatepickerProps<WithRange>): JSX.Element => {
  const {
    // registerOptions,
    options,
    ...otherProps
  } = props

  const {
    setValue,
  } = useVerticalForm()

  return (
    <DatePicker
      customInput={
        <DatepickerInput
          // registerOptions={registerOptions}
          selectedValue={options.selected ?? null}
          {...otherProps}
        />
      }

      adjustDateOnChange={options.adjustDateOnChange}
      allowSameDay={options.allowSameDay}
      ariaDescribedBy={options.ariaDescribedBy}
      ariaInvalid={options.ariaInvalid}
      ariaLabelClose={options.ariaLabelClose}
      ariaLabelledBy={options.ariaLabelledBy}
      ariaRequired={options.ariaRequired}
      autoComplete={options.autoComplete ?? "off"}
      autoFocus={options.autoFocus}
      calendarClassName={options.calendarClassName}
      calendarContainer={options.calendarContainer}
      calendarStartDay={options.calendarStartDay}
      // children={options.children}
      chooseDayAriaLabelPrefix={options.chooseDayAriaLabelPrefix}
      className={options.className}
      clearButtonClassName={options.clearButtonClassName}
      clearButtonTitle={options.clearButtonTitle}
      closeOnScroll={options.closeOnScroll}
      // customInput={options.customInput}
      customInputRef={options.customInputRef}
      customTimeInput={options.customTimeInput}
      dateFormat={options.dateFormat ?? "yyyy-MM-dd"}
      dateFormatCalendar={options.dateFormatCalendar}
      dayClassName={options.dayClassName}
      weekDayClassName={options.weekDayClassName}
      monthClassName={options.monthClassName}
      timeClassName={options.timeClassName}
      disabledDayAriaLabelPrefix={options.disabledDayAriaLabelPrefix}
      disabled={options.disabled}
      disabledKeyboardNavigation={options.disabledKeyboardNavigation}
      dropdownMode={options.dropdownMode}
      endDate={options.endDate}
      excludeDates={options.excludeDates}
      excludeDateIntervals={options.excludeDateIntervals}
      excludeTimes={options.excludeTimes}
      filterDate={options.filterDate}
      filterTime={options.filterTime}
      fixedHeight={options.fixedHeight}
      forceShowMonthNavigation={options.forceShowMonthNavigation}
      formatWeekDay={options.formatWeekDay}
      formatWeekNumber={options.formatWeekNumber}
      highlightDates={options.highlightDates}
      id={options.id}
      includeDates={options.includeDates}
      includeDateIntervals={options.includeDateIntervals}
      includeTimes={options.includeTimes}
      injectTimes={options.injectTimes}
      inline={options.inline}
      focusSelectedMonth={options.focusSelectedMonth}
      isClearable={options.isClearable}
      locale={options.locale ?? "fr"}
      maxDate={options.maxDate}
      maxTime={options.maxTime}
      minDate={options.minDate}
      minTime={options.minTime}
      monthsShown={options.monthsShown}
      name={options.name}
      nextMonthAriaLabel={options.nextMonthAriaLabel}
      nextMonthButtonLabel={options.nextMonthButtonLabel}
      nextYearAriaLabel={options.nextYearAriaLabel}
      nextYearButtonLabel={options.nextYearButtonLabel}
      onBlur={options.onBlur}
      onCalendarClose={options.onCalendarClose}
      onCalendarOpen={options.onCalendarOpen}
      onChange={
        (date, event) => {
          const utcDate: Date | Array<Date | null> | null = Array.isArray(date)
            ? date.map((d) => {
              if (d === null) return null
              return new Date(d.getTime() - (d.getTimezoneOffset() * 60000))
            })
            : date !== null
              ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
              : null

          options.name !== undefined && setValue(options.name, utcDate)
          options.onChange?.(utcDate as typeof date, event)
        }
      }
      onChangeRaw={options.onChangeRaw}
      onClickOutside={options.onClickOutside}
      onDayMouseEnter={options.onDayMouseEnter}
      onFocus={options.onFocus}
      onInputClick={options.onInputClick}
      onInputError={options.onInputError}
      onKeyDown={options.onKeyDown}
      onMonthChange={options.onMonthChange}
      onMonthMouseLeave={options.onMonthMouseLeave}
      onSelect={options.onSelect}
      onWeekSelect={options.onWeekSelect}
      onYearChange={options.onYearChange}
      open={options.open}
      openToDate={options.openToDate}
      peekNextMonth={options.peekNextMonth}
      placeholderText={options.placeholderText}
      popperClassName={options.popperClassName}
      popperContainer={options.popperContainer}
      popperModifiers={options.popperModifiers}
      popperPlacement={options.popperPlacement}
      popperProps={options.popperProps}
      preventOpenOnFocus={options.preventOpenOnFocus}
      previousMonthAriaLabel={options.previousMonthAriaLabel}
      previousMonthButtonLabel={options.previousMonthButtonLabel}
      previousYearAriaLabel={options.previousYearAriaLabel}
      previousYearButtonLabel={options.previousYearButtonLabel}
      readOnly={options.readOnly}
      renderCustomHeader={options.renderCustomHeader}
      renderDayContents={options.renderDayContents}
      required={options.required}
      scrollableMonthYearDropdown={options.scrollableMonthYearDropdown}
      scrollableYearDropdown={options.scrollableYearDropdown}
      selected={options.selected}
      selectsEnd={options.selectsEnd}
      selectsStart={options.selectsStart}
      selectsRange={options.selectsRange}
      shouldCloseOnSelect={options.shouldCloseOnSelect}
      showDisabledMonthNavigation={options.showDisabledMonthNavigation}
      showFullMonthYearPicker={options.showFullMonthYearPicker}
      showMonthDropdown={options.showMonthDropdown}
      showMonthYearDropdown={options.showMonthYearDropdown}
      showMonthYearPicker={options.showMonthYearPicker}
      showPopperArrow={options.showPopperArrow}
      showPreviousMonths={options.showPreviousMonths}
      showQuarterYearPicker={options.showQuarterYearPicker}
      showTimeInput={options.showTimeInput}
      showTimeSelect={options.showTimeSelect}
      showTimeSelectOnly={options.showTimeSelectOnly}
      showTwoColumnMonthYearPicker={options.showTwoColumnMonthYearPicker}
      showFourColumnMonthYearPicker={options.showFourColumnMonthYearPicker}
      showWeekNumbers={options.showWeekNumbers}
      showYearDropdown={options.showYearDropdown}
      showYearPicker={options.showYearPicker}
      startDate={options.startDate}
      startOpen={options.startOpen}
      strictParsing={options.strictParsing}
      tabIndex={options.tabIndex}
      timeCaption={options.timeCaption}
      timeFormat={options.timeFormat ?? "hh:mm"}
      timeInputLabel={options.timeInputLabel}
      timeIntervals={options.timeIntervals}
      title={options.title}
      todayButton={options.todayButton}
      useShortMonthInDropdown={options.useShortMonthInDropdown}
      useWeekdaysShort={options.useWeekdaysShort}
      weekAriaLabelPrefix={options.weekAriaLabelPrefix}
      monthAriaLabelPrefix={options.monthAriaLabelPrefix}
      value={options.value}
      weekLabel={options.weekLabel}
      withPortal={options.withPortal}
      portalId={options.portalId}
      portalHost={options.portalHost}
      wrapperClassName={options.wrapperClassName}
      yearDropdownItemNumber={options.yearDropdownItemNumber}
      excludeScrollbar={options.excludeScrollbar}
      enableTabLoop={options.enableTabLoop}
      yearItemNumber={options.yearItemNumber}
    />
  )
}

export default Datepicker
