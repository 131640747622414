// ** React Imports
import React, { useRef, useState, createContext, useEffect, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
import useApi from "hooks/useApi"
// ** Conf & helpers
import { type User, } from "conf/types/User"
import { defaults, } from "conf/auth"
// ** Styles
// ** Images

interface AuthContextProps {
  readonly connectedUser: User | null
  readonly setConnectedUser: React.Dispatch<React.SetStateAction<User | null>>
  readonly token: React.MutableRefObject<string | undefined>
}

const AuthContext = createContext<AuthContextProps>({
  connectedUser: null,
  setConnectedUser: () => {},
  token: { current: undefined, },
})

const useAuth = (): AuthContextProps => useContext(AuthContext)


interface AuthProviderProps {
  children: JSX.Element
}

const AuthProvider = ({ children, }: AuthProviderProps): JSX.Element => {
  const [ connectedUser, setConnectedUser, ] = useState<User | null>(null)

  // const token = useRef<string>(localStorage.getItem("token") !== null ? localStorage.getItem("token") : undefined)
  const token = useRef<string | undefined>(localStorage.getItem("token") ?? undefined)
  const resetSessionId = useRef<number>()
  const logoutDelay = defaults.logoutDelay

  const autoLoginCommand = useApi<User>({
    status200: (user, errors) => {
      if (errors.length === 0 && user !== null) {
        setConnectedUser(user)
      }
    },
  })

  useEffect(() => {
    if (connectedUser !== null) return
    const storedToken = localStorage.getItem("token")
    if (storedToken !== null) {
      token.current = storedToken
      void autoLoginCommand.request({
        url: "/user",
        method: "get",
        authorization: storedToken,
      })
    }
  }, [ connectedUser, ])

  useEffect(() => {
    if (token === undefined) return

    if (resetSessionId !== undefined) window.clearTimeout(resetSessionId.current)

    if (localStorage.getItem("token") === null) {
      resetSessionId.current = window.setTimeout(() => {
        token.current = undefined
        location.reload()
      }, logoutDelay)
    }
  }, [ token, ])

  return (
    <AuthContext.Provider value={{
      connectedUser,
      setConnectedUser,
      token,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export { useAuth, AuthProvider, }
