// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images


export const removeAttribute = (obj: Record<string, unknown>, attr: string | string[]): Record<string, unknown> => {
  if (!Array.isArray(attr)) attr = [ attr, ]

  return Object.keys(obj).reduce(
    (accumulator, currentValue) => {
      if (!attr.includes(currentValue))
        return { ...accumulator, [currentValue]: isObject(obj[currentValue]) ? removeAttribute(obj[currentValue] as Record<string, unknown>, attr) : obj[currentValue], }
      else
        return accumulator
    }
    , {})
}

export const getNestedValue = (obj: Record<string, unknown>, path: string | string[], defaultValue?: any): any => {
  if (obj === undefined) return defaultValue
  if (!Array.isArray(path)) return getNestedValue(obj, path.split("."), defaultValue)
  if (path.length === 0) return obj ?? defaultValue

  return getNestedValue(obj[path.shift() as string] as Record<string, unknown>, path, defaultValue)
}

// ** Log an object for debug
export const logObject = (obj: Record<string, unknown>, logObject: boolean = true): string => {
  const val = JSON.stringify(obj, null, " ")
  if (logObject) console.log(val) // eslint-disable-line no-console
  return val
}


export const swapObject = (obj: Record<string, string>): Record<string, string> => {
  return Object.keys(obj).reduce((r, key) => (Object.assign(r, {
    [obj[key]]: key,
  })), {})
}

// ** Returns true if the item provided is an object
export const isObject = (item: any): boolean => (item !== undefined && item !== null && typeof item === "object" && !Array.isArray(item) && item.constructor !== Date)

//* Recusively flatten an object
export const flattenObject = (obj: Record<string, unknown>, separator: string = "."): Record<string, unknown> => {
  const result: Record<string, unknown> = {}
  for (const key in obj) {
    if (isObject(obj[key])) {
      const temp = flattenObject(obj[key] as Record<string, unknown>)
      for (const subKey in temp) {
        result[`${key}${separator}${subKey}`] = temp[subKey]
      }
    } else {
      result[key] = obj[key]
    }
  }
  return result
}
