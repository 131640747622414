// ** React Imports
import { useState, useCallback, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import axios, { type ResponseType, type responseEncoding, type Method, type AxiosResponse, } from "axios"
import { toast, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
import { useAuth, } from "utility/context/Auth"
// ** Conf & helpers
import defaultConfig from "conf/api"
import { isObject, } from "utility/helpers/object"
// ** Styles
// ** Images

import messages from "conf/messages.json"

type ErrorKeys = keyof typeof messages.error

// export type ResponseData = Record<string, unknown> | null

type ResponseError = {
  message: string
  details?: {
    path: string
    method: string
    previous?: Array<{
      message: string
      code: number
      file: string
      line: number
      trace: Array<{
        file: string
        line: number
        function: string
        class: string
        type: string
      }>
    }>
  }
}

type ResponseDebug = {
  clientIP: string
  path: string
  method: string
  vars: Record<string, string>
}

type RequestParam = {
  url: string
  method?: Method
  contentType?: string
  parameters?: Record<string, string | number | boolean | Date | Record<string, string | number | boolean | Date>>
  authorization?: string
  timeout?: number
  responseType?: ResponseType
  responseEncoding?: responseEncoding
}

type Response<T = any> = {
  errors: ResponseError[]
  debug?: ResponseDebug
} & AxiosResponse<T>

type Digits = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
export type ResponseStatus = `${Digits}${Digits}${Digits}`


const convertDatesInResponse = (response: any): any => {
  if (Array.isArray(response)) {
    return response.map(convertDatesInResponse)
  } else if (isObject(response)) {
    return Object.keys(response).reduce((acc: Record<string, any>, key) => {
      acc[key] = convertDatesInResponse(response[key])
      return acc
    }, {})
  } else if (typeof response === "string" && !isNaN(Date.parse(response))) {
    return new Date(response)
  } else {
    return response
  }
}

export default function useApi<T>(
  handdle: {
    [key in `status${ResponseStatus}`]?: (data: T | null, errors: ResponseError[], ...callbackParams: any[]) => void
  } & {
    statusAll?: (data: T | null, errors: ResponseError[], status: ResponseStatus, ...callbackParams: any[]) => void
  } & {
    finally?: (...callbackParams: any[]) => void
  }
): {
    data: T | null
    errors: ResponseError[]
    status: number | null
    loading: boolean
    request: (params: RequestParam, ...callbackParams: Parameters<any>) => Promise<Response<T>>
  } {
  const [ data, setData, ] = useState<T | null>(null)
  const [ errors, setErrors, ] = useState<ResponseError[]>([])
  const [ status, setStatus, ] = useState<number | null>(null)
  const [ loading, setLoading, ] = useState(false)
  const { token, } = useAuth()

  const axiosInstance = axios.create({
    // withCredentials: true,
    // withXSRFToken: true,
  })

  useEffect(() => {
    if (errors.length === 0) return
    errors.forEach((error: ResponseError) => {
      if (
        error.message === "tokenBlacklisted" ||
        error.message === "tokenExpired" ||
        error.message === "tokenInvalid"
      ) {
        token.current = undefined
      }
      toast.error(messages.error[error.message as ErrorKeys])
    })
  }, [ errors, ])

  const request = useCallback(async (params: RequestParam, ...callbackParams: Parameters<any>): Promise<Response<T>> => {
    const {
      url,
      method = "get",
      contentType = method === "put" || method === "patch" ? "application/json;charset=utf-8" : "multipart/form-data",
      parameters = {},
      authorization = token.current,
      ...rest
    } = params

    // Transform boolean to numbers, date to timestamp & strigify objects
    Object.keys(parameters).forEach(key => {
      if (typeof parameters[key] === "boolean") parameters[key] = Number(parameters[key])
      else if (parameters[key] instanceof Date) parameters[key] = Math.floor((parameters[key] as Date).getTime() / 1000)
      else if (typeof parameters[key] === "object") parameters[key] = JSON.stringify(parameters[key])
    })

    const config = { ...defaultConfig, ...rest, }

    const requestParams = {
      url,
      method,
      baseURL: config.baseUrl,
      headers: {
        "Content-Type": contentType,
        ...(authorization !== undefined ? { Authorization: authorization, } : null),
        Accept: "application/json",
      },
      ...(method === "get" ? { params: parameters, } : null),
      ...(method === "post" || method === "delete"
        ? {
          data: Object.keys(parameters).reduce((accumulator, currentValue) => {
            accumulator.append(currentValue, parameters[currentValue] as string)
            return accumulator
          }, new FormData()),
        }
        : null),
      ...(method === "put" || method === "patch" ? { data: parameters, } : null),
      timeout: config.timeout * 1000,
      responseType: config.responseType as ResponseType,
      responseEncoding: config.responseEncoding,
      // validateStatus: status => {
      // validateStatus: () => {
      //   // console.log(`%c Status : ${status}`, "background: blue; color: white")
      //   return true
      // },
    }

    setLoading(true)

    return await new Promise((resolve, reject) => {
      axiosInstance
        .request<Response<T>>(requestParams)
        .then(response => {
          setErrors(response.data.errors)
          setData(response.data.data)
          setStatus(response.status)
          if (response.data.debug !== undefined) {
            console.log("%c Status : ", "background: #222; color: #bada55", response.status) // eslint-disable-line no-console
            console.log("%c Debug : ", "background: #222; color: #bada55", response.data.debug) // eslint-disable-line no-console
            console.log("%c Errors : ", "background: #222; color: #bada55", response.data.errors) // eslint-disable-line no-console
            console.log("%c Data : ", "background: #222; color: #bada55", response.data) // eslint-disable-line no-console
          }

          if (response.headers.authorization !== undefined) {
            token.current = response.headers.authorization
          }

          return { ...response, data: convertDatesInResponse(response.data), }
        })
        .then(response => {
          const status = response.status.toString() as ResponseStatus

          handdle[`status${status}`]?.apply(null, [ response.data.data, response.data.errors ?? [], ...callbackParams, ])
          handdle.statusAll?.apply(null, [ response.data.data, response.data.errors ?? [], status, ...callbackParams, ])
          resolve(response.data)
        })
        .catch(error => {
          const errCode = error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable"
          toast.error(messages.error[errCode])

          setErrors([ { message: error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable", }, ])
          setData(null)
          reject(error)
        })
        .finally(() => {
          if (typeof handdle.finally === "function") handdle.finally(callbackParams)
          setLoading(false)
        })
    })
  // }, [ axios.request, ])
  }, [ axiosInstance, ])

  return {
    data,
    errors,
    status,
    loading,
    request,
  }
}
